/* error boundry start */
.error-boundary {
    display: flex;
    align-content: center;
    align-items: center;
    height: 100vh;
}
  
.error-boundary .error-boundary-text {
    font-size: 1.25rem;
    text-align: center;
}
/* error boundry end */
body, #root, .main-layout{
    min-height: 100vh;
}
.page-content{
    /* min-height: 30rem; */
    min-height: 45rem;
}
body .main-layout{
    padding-top: 58px;
}
footer{
  /*  position: absolute !important; */
   position: relative !important;
    left: 0;
    bottom: 0;
    right: 0;
}
/* .logo img {
    width: 50px
} */
.logo img {
    /* max-width: 200px; */
    max-width: 210px;
    width: unset !important;
}
.search-banner-images
{
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
.application-link-section img{
    width: 175px;
    max-width: 100%;
}
/* .navbar .dropdown-menu
{
    margin-top: -1.3rem;
} */
.vendor-outlet-list-map
{
    top: 50px !important;
}
@media (min-width: 992px){
    .vendor-outlet-list-map .map-full {
        /* height: calc(100vh - 0px); */
        height: calc(100vh - 50px);
    }
}
.cursor-pointer{
    cursor: pointer;
}
.custom-link.active .nav-div{
    color: #14b4ac;
    text-decoration: none;
}

.devices .app_type{
    width: 50px;
}
.react-tel-input .form-control
{
    position: relative;
    width: 100% !important;
    height: inherit !important;
    font-size: inherit !important;
    letter-spacing: .01rem;
    padding-left: 48px;
    margin-left: 0;
}
.refer-section{
    /* background-position: 2px 8px; */
    background-position: 0px 0px;
    background-size: 420px 800px;
    background-repeat: no-repeat;
}
.refer-image{
    width: 260px;
}
.refer-section .container{
    position: relative;
    text-align: center;
    color: white;
}
.refercode-image{
    height: 50px;
    width: 280px;
}
.refer-section .overlay-text{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.user-qrcode{
    width: 130px;
}
.follow-heart{
    margin-top: 0.8rem;
}
.overflow-scroll-section{
    overflow-x: hidden;
    overflow-y: auto;
    
}
.overflow-section-height{
    height: 55em;
}
.signin-profile-upload .edit-profile-pic{
    right: 0 !important;
    top: 0 !important;
}
.reward-progress-section{
    display: none;
}
.reward-progress-section:last-of-type{
    display: block;
}
.progress{
    background: #b1b1b1;
}
.fade.toast.show{
    z-index: 99999;
}
.card-fav-icon{
    opacity: 1;
}
.card-fav-icon:hover{
    /* color: #dc3545 !important;*/
}
.free-product-overlay img{
    margin-left: -30px;
}
/* gallery */
.ReactModalPortal .ReactModal__Overlay{
    z-index: 1050 !important;
}
.row-image.img-fluid{
    /* height: 250px; */
    height: 200px;
}
img.row-image.img-fluid
{
    /*  object-fit: fill; */
    object-fit: contain;
}
@media (max-width: 1200px) {
    .home-vendor .vendor-flex {
        display: block !important;
    }
}
.home-slider .swiper-horizontal>.swiper-pagination-bullets, .home-slider .swiper-pagination-bullets.swiper-pagination-horizontal, .home-slider .swiper-pagination-custom, .home-slider .swiper-pagination-fraction{
    bottom: -5px !important;
}
.home-slider .swiper-button-next, .swiper-button-prev{
    color: #14b4ac !important;
}
/* .home-slider .swiper {
    padding: 0 50px 0 50px  !important;
  } */
  
/* .product-detail-image .img-fluid{
    height: auto;
} */
.offer-image .img-fluid{
    height: 180px;
    aspect-ratio: 1/1;
    object-fit: contain;
}
/* .h-280px{
    height: 280px;
} */
/* review list */
.all-review-star div{
    justify-content: center;
}
.all-review-header{
    padding-bottom: 1rem;
    border-bottom: 1px solid #e9ecef;
}
.review_date{
    width: 7rem;
}
/* add review */
.star-rating {
    display:flex;
    flex-direction: row-reverse;
    font-size:1.5em;
    justify-content:space-around;
    text-align:center;
    width:5em;
  }
  
  .star-rating input {
    display:none;
  }
  
  .star-rating label {
    color:#808080;
    cursor:pointer;
  }
  
  .star-rating :checked ~ label {
    color:#ffc108;
  }
  
  .star-rating label:hover,
  .star-rating label:hover ~ label {
    color:#ffc108;
  }
  /* home page search */
  .search-category-select2 .bootstrap-select {
    width: 100% !important;
  }

  .vendor-profile-pic img {
    max-width: 170px;
    width: 100%;
}
.add-review-btn {
    bottom: -27px;
    /* margin-right: 17px; */
}
@media (min-width: 576px){
    .add-review-btn {
        margin-right: 17px;
    }
    
}
@media only screen and (max-width: 600px) {
    /* mobile screen */
    .transaction-point{
        margin-bottom: 5px;
    }
}
.review-average .progress {
    height: 10px;
}
.bg-color-orange{
    background-color: #FF8C00;
}
.bg-color-dark-green{
    background-color: #006400;
}
/* scroll to top button */
#scrollTopbutton {
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    transition: background-color .3s, 
      opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    font-size: 20px;
    line-height: 35px;
    border-radius: 100%;
    padding:  0 0 0 5px;
  }
  #scrollTopbutton.show {
    opacity: 1;
    visibility: visible;
  }
/* shimmer effect */
.shimmer {
    background: #ced4da;
    display:inline-block;
    -webkit-mask:linear-gradient(-60deg,#000 30%,#0005,#000 70%) right/300% 100%;
    background-repeat: no-repeat;
    animation: shimmer 2.5s infinite;
    font-size: 50px;
  }
  
  @keyframes shimmer {
    100% {-webkit-mask-position:left}
  }
/* gallery */
.gallery-row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }
  
.gallery-column {
    flex: 25%;
    max-width: 33.3%;
    padding: 0 4px;
}
  
.gallery-column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s linear;
}
  
@media screen and (max-width: 700px) {
    .gallery-column {
      flex: 100%;
      max-width: 100%;
    }
}
/* select2 in home and vendor/outlet */
.select-dropdown-section .inner.show{
    max-height: 350px !important;
}
/* map info window */
.map-address{
    font-size: 10px;
    /* max-width: 200px; */
}
.info-window{
    max-width: 250px;
}
/* push notification */
.notification-logo{
    width: 40px;
}
.notification-header-title{
    max-width: 180px;
    white-space: normal;
    word-wrap: break-word;
}
/* nav menu hover */
.nav-animation {
    display: inline-block;
    position: relative;
}
  
.nav-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: 
    var(--bs-primary);
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
.nav-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}
.btn-signin{
    letter-spacing: 0.1em;
}
.navmenu-section .nav-item-setion{
    margin : 0 15px;
}
@media screen and (max-width: 990px) {
    .navmenu-section .nav-item-icon{
        margin : 0 0 0 15px !important;
    }
    .navmenu-section .nav-item-icon:last-child{
        margin : 15px 0 0 15px !important;
    }
}

.navmenu-section .nav-link{
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.navmenu-section .nav-item-icon a.show i, .navmenu-section .nav-item-icon a.show svg {
    color: var(--bs-primary) !important;
}
.brand-container .brand-section{
    line-height: 17.6px;
}
.brand-section small{
    font-size: 12px;
}
.signin-logo{
 /*    max-width: 6rem; */
 /* max-width: 200px; */
 max-width: 210px;
}
.coming-soon-box{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
/* welcome content */
.welcome-accordian
{
    background-color: unset !important;
    color: black !important;
}
/* refer and earn */
.refer_n_ern_title{
	color: #19588c;
}
.refer_n_ern_text{
	font-size: 12px;
}
.refer_n_ern_btn{
	background: rgb(9,111,110);
    background: linear-gradient(280deg, rgba(9,111,110,1) 0%, rgba(29,175,190,1) 100%); 
	border: none;
	letter-spacing: normal;
	font-weight: normal;
	text-transform: capitalize;
	padding: 8px 20px;
	border-radius: 12px;
	opacity: 0.9;
}
.refer_n_ern_btn:hover{
	opacity: 1;
}
.refer_n_ern_sub_title{
	font-size: 1em;
}
@media (min-width: 1199px) {
    .refer_n_ern_title{
        font-size:80px;
        color: #19588c;
    }
}
/* password eye icon */
.textbox-group {
    position: relative;
} 
.input-textbox {
    padding-right: 30px; /* Adjust the value as needed */
}
/* verify otp */
.eye-icon {
    position: absolute;
    top: 50%;
    right: 10px; /* Adjust the value as needed */
    transform: translateY(-50%);
    cursor: pointer;
}
/* Suggestion in vendor, outlet and home search section */
.suggestions-list{
    padding: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    list-style-type: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: absolute;
    width: 100%;
    z-index: 10;
    color: #000 !important;
}
.suggestion-item {
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
.suggestion-item:hover {
    color: var(--bs-primary);
    text-decoration: none;
    background-color: #f8f9fa;
}
.suggestion-item:focus {
    color: #fff !important;
    text-decoration: none;
    background-color: var(--bs-primary) !important;
}
/* react select ..................*/
/* .form-group-select{
    margin-bottom: 1.5rem;
    color: #8898aa;
  } */
.custom-select-input .select__input-container, .custom-select-input .select__single-value .custom-select-input .select__placeholder{
    /* color: #8898aa !important; */
    color: #000 !important;
}
.custom-select .select__dropdown-indicator, .custom-select-input .select__indicator-separator, .custom-select-input .select__indicator{
    color: #6c757d !important;
}
.custom-select-input.form-select-list .select__control{
    width: 100%;
    padding: 0.025rem 0;
    font-size: 1rem;
    /* font-weight: 400; */
    line-height: 1.6;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.4rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-select-input.form-select-list .select__control--is-focused{
    color: #212529;
    background-color: #fff;
    border-color: #a7b3fc;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(20 180 172 / 25%);
}
.custom-select-input .select__option{
    color: #000;
}

  /* .custom-select-input .select__control--menu-is-open{
    border-color: var(--theme-color) !important;
    box-shadow: unset !important;
  } */
  
.custom-select-input .select__menu{
    z-index: 20 !important;
}
.custom-select-input .select__control--is-disabled{
    background-color: #e9ecef;
}
.custom-select-input .select__option--is-focused{
    color: var(--bs-primary);
    text-decoration: none;
    background-color: #f8f9fa;
} 
.custom-select-input .select__option--is-selected{
    background-color: var(--bs-primary) !important;
    color: #fff;
}
/* --- for home page search section react-select*/
.home-custom-select-input {
   width: 100%;
}
.home-custom-select-input .select__control, .home-custom-select-input .select__control:hover{
    border: unset !important;
    border-color: unset !important;
}
.home-custom-select-input .select__control--is-focused{
    border-color: unset !important;
    box-shadow: unset !important;
}
.home-custom-select-input .select__control--menu-is-open{
    background-color: #ced4da;
}

.home-suggestion{
    position: relative;
}
.home-suggestion .suggestions-list{
    position: absolute;
    top: 112%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    list-style: none;
    padding: 0;
    margin: 0;
}
@media (max-width: 991.98px){
    .home-custom-select-input{
        padding: 0px 20px;
    }
    .home-suggestion .suggestions-list{
        top: 107%;
        left: 32px;
        width: 91%;
    }
}
@media (max-width: 767.98px) {
    .home-suggestion .suggestions-list{
        width: 88%;
    }
}
@media (max-width: 575.98px) {
    .home-suggestion .suggestions-list{
        width: 82%;
    }
}
#contentpageModal .modal-content{
    height: 500px;
}
/*Bhaumik Patel*/
.mfw-100{min-width: 100px;}